import React from 'react';
import { Clock, Eye } from 'lucide-react';

interface LiveStreamCardProps {
    title: string;
    channelName: string;
    viewers: number;
    category: string;
    time: string;
    watching: number;
    thumbnail?: string | null;
    isLive?: boolean;
    className?: string;
    channelAvatar?: string;
    created_at?: string;
}

const LiveStreamCard: React.FC<LiveStreamCardProps> = ({
    title,
    channelName,
    category,
    time,
    watching,
    thumbnail = null,
    isLive = true,
    className = '',
    channelAvatar,
    created_at,
}) => {
    return (
        <div className={`flex flex-col w-full max-w-[280px] gap-3 ${className}`}>
            {/* Video Thumbnail Section */}
            <div className="relative w-full aspect-[2/1] bg-[#DBDBDB] overflow-hidden ">
                {thumbnail ? (
                    <img
                        src={thumbnail}
                        alt={title}
                        className="absolute inset-0 w-full h-full object-cover"
                    />
                ) : (
                        <div className="absolute inset-0 bg-[#DBDBDB]" />
                )}

                <div className="absolute top-2 left-2 right-2 flex justify-between items-start">
                    {/* Live Tag */}
                    {isLive && (
                        <div className="flex h-4 items-center justify-center bg-[#EC5353] rounded px-0.5 py-0.5 min-w-[38px]">
                            <div className="w-3 h-3 flex items-center justify-center">
                                <div className="w-1 h-1 rounded-full bg-white" />
                            </div>
                            <span className="text-white text-[8px] font-bold font-outfit px-0.5 whitespace-nowrap">LIVE</span>
                        </div>
                    )}

                    {/* Time Tag */}
                    <div className="h-4 flex items-center bg-black/70 px-1.5 rounded-[10px] min-w-[32px] justify-center">
                        <span className="text-white text-[8px] font-semibold font-['Exo_2'] whitespace-nowrap">
                            {time}
                        </span>
                    </div>
                </div>
            </div>

            {/* Content Section */}
            <div className="flex flex-col gap-1 w-full">
                {/* Details Row */}
                <div className="flex flex-wrap items-center gap-x-2 gap-y-1 min-h-[16px]">
                    <span className="text-[#A854FD] text-xs font-outfit whitespace-nowrap">
                        {category}
                    </span>
                    <div className="flex flex-wrap items-center gap-x-2 gap-y-1">
                        <div className="flex items-center gap-1">
                            <Clock className="w-4 h-4 min-w-[16px] text-[#9FA0AD]" />
                            <span className="text-[#9FA0AD] text-xs font-outfit whitespace-nowrap">{created_at}</span>
                        </div>
                        <div className="flex items-center gap-1">
                            <Eye className="w-4 h-4 min-w-[16px] text-[#9FA0AD]" />
                            <span className="text-[#9FA0AD] text-xs font-outfit whitespace-nowrap">{watching}</span>
                        </div>
                    </div>
                </div>

                {/* Title */}
                <h3 className="text-white font-semibold text-sm font-outfit leading-5 min-h-[40px] line-clamp-2">
                    {title}
                </h3>

                {/* Channel Info */}
                <div className="flex items-center gap-2 mt-1">
                    {channelAvatar ? (
                        <img
                            src={channelAvatar}
                            alt={channelName}
                            className="w-6 h-6 min-w-[24px] rounded bg-white"
                        />
                    ) : (
                        <div className="w-6 h-6 min-w-[24px] rounded bg-white" />
                    )}
                    <span className="text-[#9FA0AD] text-xs font-outfit truncate">
                        {channelName}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default LiveStreamCard;